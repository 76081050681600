import React, { Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import Login from './Login'
import FullSpinner from './components/FullSpinner/FullSpinner'

import PMHome from './PMHome'

const BudgetHead = React.lazy(() => import('./pages/budgets/BudgetHead'))
const BudgetApprovedView = React.lazy(() =>
    import('./pages/budgets/BudgetApprovedView')
)
const BudgetNew = React.lazy(() => import('./pages/budgets/BudgetNew'))
const BudgetPeople = React.lazy(() => import('./pages/budgets/BudgetPeople'))
const BudgetPlanning = React.lazy(() =>
    import('./pages/budgets/BudgetPlanning')
)
const BudgetPlanningPerMonth = React.lazy(() =>
    import('./pages/budgets/BudgetPlanningPerMonth')
)
const BudgetNotes = React.lazy(() => import('./pages/budgets/BudgetNotes'))
const BudgetApproveList = React.lazy(() =>
    import('./pages/budgets/BudgetApproveList')
)
const BudgetApproveDetail = React.lazy(() =>
    import('./pages/budgets/BudgetApproveDetail')
)
const BudgetActiveList = React.lazy(() =>
    import('./pages/budgets/BudgetActiveList')
)
const BudgetActiveDetail = React.lazy(() =>
    import('./pages/budgets/BudgetActiveDetail')
)
const BudgetResources = React.lazy(() =>
    import('./pages/budgets/BudgetResources')
)

const ReportActuals = React.lazy(() => import('./pages/reports/ReportActuals'))
const ReportSchedule = React.lazy(() =>
    import('./pages/reports/ReportSchedule')
)
const ReportScheduleDaily = React.lazy(() =>
    import('./pages/reports/ReportScheduleDaily')
)
const ReportFinance = React.lazy(() => import('./pages/reports/ReportFinance'))
const ReportTimesheet = React.lazy(() =>
    import('./pages/reports/ReportTimesheet')
)
const ReportBudgets = React.lazy(() => import('./pages/reports/ReportBudgets'))

const Externals = React.lazy(() => import('./pages/Externals'))
const EmployeesRates = React.lazy(() => import('./pages/EmployeesRates'))
const Hierarchies = React.lazy(() => import('./pages/Hierarchies'))
const UsersOrg = React.lazy(() => import('./pages/UsersOrg'))

// import Timesheet from "./pages/Timesheet";
const Expenses = React.lazy(() => import('./pages/Expenses'))
const Fulfillment = React.lazy(() => import('./pages/Fulfillment'))
const Timesheet = React.lazy(() =>
    import('./pages/employee/Timesheet/Timesheet')
)
const ClosedTimesheet = React.lazy(() =>
    import('./pages/employee/Timesheet/ClosedTimesheet')
)
const Timekeeper = React.lazy(() =>
    import('./pages/employee/Timekeeper/Timekeeper')
)

const CustomersList = React.lazy(() =>
    import('./pages/customers/CustomersList')
)
const CustomersDetail = React.lazy(() =>
    import('./pages/customers/CustomersDetail')
)
const CustomersDetailEdit = React.lazy(() =>
    import('./pages/customers/CustomersDetailEdit')
)

const RatesGroups = React.lazy(() => import('./pages/rates/RatesGroups'))
const RatesGroupsDetail = React.lazy(() =>
    import('./pages/rates/RatesGroupsDetail')
)
const ExternalDetail = React.lazy(() => import('./pages/ExternalDetail'))
const ResourceType = React.lazy(() =>
    import('./pages/resourcetype/ResourceType')
)
const ResourceTypeDetail = React.lazy(() =>
    import('./pages/resourcetype/ResourceTypeDetail')
)
const ActualMove = React.lazy(() => import('./pages/ActualMove/ActualMove'))

const Refresh = React.lazy(() => import('./Refresh'))

const ProfileSkills = React.lazy(() => import('./pages/profile/ProfileSkill'))
const Skills = React.lazy(() => import('./pages/skills/Skills'))
const UsersSkills = React.lazy(() => import('./pages/skills/UsersSkills'))

export default class Routing extends React.Component {
    render() {
        return (
            <HashRouter>
                <Suspense fallback={<FullSpinner />}>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/" component={Fulfillment} />

                        {/* <Route exact path="/timesheet/:year/:month" component={Timesheet} /> */}
                        <Route
                            exact
                            path="/timesheet/:year/:month"
                            component={Timesheet}
                        />
                        <Route
                            exact
                            path="/timekeeper/:year/:month"
                            component={Timekeeper}
                        />
                        <Route
                            exact
                            path="/expenses/:year/:month"
                            component={Expenses}
                        />
                        <Route
                            exact
                            path="/archivets/:year/:month"
                            component={ClosedTimesheet}
                        />
                        <Route exact path="/refresh" component={Refresh} />
                        <Route
                            exact
                            path="/projects/budgets"
                            component={PMHome}
                        />
                        <Route
                            exact
                            path="/projects/budgets/new"
                            component={BudgetNew}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId"
                            component={BudgetApprovedView}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/head"
                            component={BudgetHead}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/people"
                            component={BudgetPeople}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/planning"
                            component={BudgetPlanning}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/planning/:year/:month"
                            component={BudgetPlanningPerMonth}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/notes"
                            component={BudgetNotes}
                        />
                        <Route
                            exact
                            path="/projects/budgets/:budgetId/resources"
                            component={BudgetResources}
                        />
                        <Route
                            exact
                            path="/projects/customers"
                            component={CustomersList}
                        />
                        <Route
                            exact
                            path="/employees/actualmove"
                            component={ActualMove}
                        />
                        <Route
                            exact
                            path="/projects/customers/:customerId"
                            component={CustomersDetail}
                        />
                        <Route
                            exact
                            path="/projects/customers/:customerId/edit"
                            component={CustomersDetailEdit}
                        />
                        <Route
                            exact
                            path="/projects/usersbudgets"
                            component={BudgetApproveList}
                        />
                        <Route
                            exact
                            path="/projects/usersbudgets/:budgetId"
                            component={BudgetApproveDetail}
                        />
                        <Route
                            exact
                            path="/projects/allbudgets"
                            component={BudgetActiveList}
                        />
                        <Route
                            exact
                            path="/projects/allbudgets/:budgetId"
                            component={BudgetActiveDetail}
                        />
                        <Route
                            exact
                            path="/projects/rates"
                            component={RatesGroups}
                        />
                        <Route
                            exact
                            path="/projects/rates/:rateid"
                            component={RatesGroupsDetail}
                        />
                        <Route
                            exact
                            path="/projects/resourcetype"
                            component={ResourceType}
                        />
                        <Route
                            exact
                            path="/projects/resourcetype/:id"
                            component={ResourceTypeDetail}
                        />

                        <Route
                            exact
                            path="/reports/schedule"
                            component={ReportSchedule}
                        />
                        <Route
                            exact
                            path="/reports/actuals"
                            component={ReportActuals}
                        />
                        <Route
                            exact
                            path="/reports/finance"
                            component={ReportFinance}
                        />
                        <Route
                            exact
                            path="/reports/dailyplans"
                            component={ReportScheduleDaily}
                        />
                        <Route
                            exact
                            path="/reports/timesheet"
                            component={ReportTimesheet}
                        />
                        <Route
                            exact
                            path="/reports/budgets"
                            component={ReportBudgets}
                        />
                        <Route exact path="/externals" component={Externals} />
                        <Route
                            exact
                            path="/externals/details/:id"
                            component={ExternalDetail}
                        />
                        <Route
                            exact
                            path="/employees/rates"
                            component={EmployeesRates}
                        />
                        <Route
                            exact
                            path="/employees/hierarchies"
                            component={Hierarchies}
                        />
                        {process.env.REACT_APP_SHOWSKILLS === 'true' ? (
                            <Route
                                exact
                                path="/profile/skills"
                                component={ProfileSkills}
                            />
                        ) : null}
                        {process.env.REACT_APP_SHOWSKILLS === 'true' ? (
                            <Route exact path="/skills" component={Skills} />
                        ) : null}
                        {process.env.REACT_APP_SHOWSKILLS === 'true' ? (
                            <Route
                                exact
                                path="/skills/users"
                                component={UsersSkills}
                            />
                        ) : null}
                        {process.env.REACT_APP_SHOWORG === 'true' ? (
                            <Route
                                exact
                                path="/employees/organizations"
                                component={UsersOrg}
                            />
                        ) : null}
                    </Switch>
                </Suspense>
            </HashRouter>
        )
    }
}
