const rp = require('request-promise')

const getMe = (token) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/me',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const user = {
  'getMe': getMe,
}
export default user;
