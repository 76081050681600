import React from 'react'
import './Layout.scss'
import { NavLink, Redirect, withRouter } from 'react-router-dom'
import Storage from './lib/Storage'
import translate from './lib/i18n/i18n'
// import ServerStatus from "./components/ServerStatus/ServerStatus";
import UserMenu from './components/UserMenu/UserMenu'
import FullSpinner from './components/FullSpinner/FullSpinner'
import ProjectMenu from './components/ProjectMenu/ProjectMenu'

import './assets/logo@4x.png'

class LayoutPM extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            validUser: true,
            _isLoading: true
        }
        this.logout = this.logout.bind(this)
    }

    async componentDidMount() {
        let user = await Storage.get('user')
        if (user === null) {
            this.setState({
                validUser: false,
                _isLoading: false
            })
        } else {
            this.setState({
                user: user,
                _isLoading: false
            })
        }
    }

    async logout() {
        await Storage.deleteAll()
        this.setState({
            validUser: false
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isLoading !== this.state._isLoading) {
            this.setState({ _isLoading: this.props.isLoading })
        }
    }

    render() {
        return (
            <div className="ts-app container-fluid f-montserrat">
                {this.state.validUser ? null : <Redirect to="/login" />}

                {this.state._isLoading ? (
                    <FullSpinner isLoading={this.state._isLoading} />
                ) : null}

                <div>
                    <div className="row ts-header-bar row  justify-content-center align-items-center no-select">
                        <div
                            className="col p-0 site-title"
                            // onClick={() => {
                            //     this.props.history.push('/')
                            // }}
                        ></div>

                        <div className="nav-bar col-10 row no-gutters p-0">
                            <div
                                className={`${
                                    this.props.path.name === '/timesheet'
                                        ? 'active'
                                        : ''
                                } navlink row no-gutters align-items-center justify-content-center f-16 ml-3`}
                            >
                                {this.props.year ? (
                                    <NavLink
                                        to={`/timesheet/${this.props.year}/${this.props.month}`}
                                    >{`${
                                        process.env.REACT_APP_TIMESHEETLABEL ||
                                        translate.common.timesheet
                                    }`}</NavLink>
                                ) : null}
                            </div>
                            {process.env.REACT_APP_EXPENSES === 'true' ? (
                                <div
                                    className={`${
                                        this.props.path.name === '/expenses'
                                            ? 'active'
                                            : ''
                                    } navlink row no-gutters align-items-center justify-content-center f-16 ml-3`}
                                >
                                    {this.props.month ? (
                                        <NavLink
                                            to={
                                                '/expenses/' +
                                                this.props.year +
                                                '/' +
                                                this.props.month
                                            }
                                        >
                                            {translate.common.expense}
                                        </NavLink>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-1 d-flex align-items-center justify-content-end site-logout pr-5">
                            <UserMenu
                                user={this.state.user}
                                logout={() => {
                                    this.logout()
                                }}
                            >
                                <div>
                                    <a href="/">
                                        <button
                                            type="button"
                                            title="Timesheet"
                                            className="btn btn-borderless btn-google-account d-flex justify-content-center align-items-center no-select px-3"
                                        >
                                            {
                                                process.env
                                                    .REACT_APP_TIMESHEETLABEL
                                            }
                                        </button>
                                    </a>
                                </div>
                            </UserMenu>
                        </div>
                    </div>

                    <div className="row mainmenu-content">
                        <div className="col-md-12">
                            <ProjectMenu
                                location={this.props.history.location.pathname}
                            />
                        </div>
                    </div>

                    <div className="row layout-content">
                        <div className="col-md-12 p-0">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                {/* <ServerStatus /> */}
            </div>
        )
    }
}

export default withRouter(LayoutPM)
