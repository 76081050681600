const rp = require('request-promise')

const getStatus = () => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/status',
    method: 'GET',
    json: true
  })
}

const info = {
  'getStatus': getStatus,
}
export default info;
