const rp = require('request-promise')

const getList = (token) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/organizations',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const customers = {
  'getList': getList,

}
export default customers;
