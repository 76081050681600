import React from 'react'
import './ProjectMenu.css'
import Storage from '../../lib/Storage'
import { Menu } from 'antd'

const { SubMenu } = Menu

export default class ProjectMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            location: props.location,
            btn: [
                {
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/projects/budgets',
                    label: 'My Budgets'
                },
                {
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/reports/schedule',
                    label: 'Overall Schedule'
                },
                {
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/reports/actuals',
                    label: 'Overall Actuals'
                },
                {
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/reports/dailyplans',
                    label: 'Monthly Schedule'
                },
                {
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/projects/customers',
                    label: 'Customers'
                }
            ]
        }
    }

    async componentDidMount() {
        let u = await Storage.get('user')
        if (u.roles.indexOf('budget-approver') >= 0) {
            let btn = this.state.btn
            if (process.env.REACT_APP_SHOWSKILLS === 'true') {
                btn.push({
                    dropdown: true,
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/skills',
                    label: 'Skills',
                    links: [
                        {
                            className: 'pm-submenu',
                            href: '/skills',
                            label: 'Skill Manager'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/skills/users',
                            label: 'User Skills'
                        }
                    ]
                })
            }
            btn.push({
                dropdown: true,
                className: 'btn btn-block btn-link pm-menu budget-approver',
                href: '/reports',
                label: 'Reports',
                links: [
                    {
                        className: 'pm-submenu',
                        href: '/reports/timesheet',
                        label: 'Timesheet Expenses'
                    },
                    {
                        className: 'pm-submenu',
                        href: '/reports/budgets',
                        label: 'Timesheet Details'
                    }
                ]
            })
            btn.push({
                className: 'btn btn-block btn-link pm-menu budget-approver',
                href: '/projects/rates',
                label: 'Rates Management'
            })
            btn.push({
                className: 'btn btn-block btn-link pm-menu budget-approver',
                href: '/projects/resourcetype',
                label: 'Resource Type'
            })
            btn.push({
                className: 'btn btn-block btn-link pm-menu budget-approver',
                href: '/reports/finance',
                label: 'Finance status'
            })
            btn.unshift({
                className: 'btn btn-block btn-link pm-menu budget-approver',
                href: '/projects/usersbudgets',
                label: 'Approve budgets'
            })
            btn.unshift({
                className: 'btn btn-block btn-link pm-menu',
                href: '/projects/allbudgets',
                label: 'Active budgets'
            })
            if (process.env.REACT_APP_SHOWORG === 'true') {
                btn.push({
                    dropdown: true,
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/employees',
                    label: 'Employee',
                    links: [
                        {
                            className: 'pm-submenu',
                            href: '/externals',
                            label: 'Externals Employees'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/rates',
                            label: 'Employees Rates'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/hierarchies',
                            label: 'Hierarchies'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/organizations',
                            label: 'Organizations Management'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/actualmove',
                            label: 'Actual Move'
                        }
                    ]
                })
            } else {
                btn.push({
                    dropdown: true,
                    className: 'btn btn-block btn-link pm-menu',
                    href: '/employees',
                    label: 'Employee',
                    links: [
                        {
                            className: 'pm-submenu',
                            href: '/externals',
                            label: 'Externals Employees'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/rates',
                            label: 'Employees Rates'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/hierarchies',
                            label: 'Hierarchies'
                        },
                        {
                            className: 'pm-submenu',
                            href: '/employees/actualmove',
                            label: 'Actual Move'
                        }
                    ]
                })
            }
            this.setState({
                btn: btn
            })
        }
    }
    async componentDidUpdate(prev) {
        if (this.props.location !== prev.location) {
            this.updateBtnClass()
        }
    }

    updateBtnClass() {
        let btn = this.state.btn.map((i) => {
            if (this.props.location === i.href) {
                i.className = ' active'
            } else {
                i.className = ''
            }
            return i
        })
        this.setState({ location: this.props.location, btn: btn })
    }

    render() {
        return (
            <Menu mode="horizontal" selectedKeys={this.props.location}>
                {this.state.btn.map((i, k) => {
                    let component = ''
                    !i.dropdown
                        ? (component = (
                              <Menu.Item key={i.href}>
                                  <a href={'#' + i.href}>{i.label}</a>
                              </Menu.Item>
                          ))
                        : (component = (
                              <SubMenu title={i.label}>
                                  {i.links.map((si, sk) => {
                                      return (
                                          <Menu.Item key={si.href}>
                                              <a href={'#' + si.href}>
                                                  {si.label}
                                              </a>
                                          </Menu.Item>
                                      )
                                  })}
                              </SubMenu>
                          ))
                    return component
                })}
            </Menu>
        )
    }
}
