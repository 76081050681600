'use stricts'

const moment = require('moment');

export default {
  'format': (d) => {
    return moment(d*1000).format('YYYY-MM-DD')
  },

  'monthString': (m) => {
    const months = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ]
    return months[m-1]
  },

  'dayOfWeekString': (m) => {
    const dow = [
      'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
    ]
    return dow[m]
  }
}
