import React from 'react';

class BudgetStatus extends React.Component {
  constructor(props){
    super(props)
    let x = this.getStyle(props.status, props.dateClose)
    this.state = {
      status: props.status,
      label: x.label,
      className: x.className,
      dateClose: props.dateClose
    }
  }

  componentDidUpdate(prevProp){
    if(prevProp.status !== this.props.status){
      let x = this.getStyle(this.props.status, this.props.dateClose)
      this.setState({
        status: this.props.status,
        label: x.label,
        className: x.className
      })
    }
    if(prevProp.dateClose !== this.props.dateClose){
      let x = this.getStyle(this.props.status, this.props.dateClose)
      this.setState({
        status: this.props.status,
        label: x.label,
        className: x.className
      })
    }
  }

  getStyle(s, d){
    let label = ''
    let className = ''
    switch (s) {
      case 'draft':
        label = 'DRAFT'
        className = 'badge badge-info'
        break;
      case 'tba':
        label = 'TO BE APPROVED'
        className = 'badge badge-warning'
        break;
      case 'approved':
        const today = new Date()
        d = (d===null)?`01/01/${today.getFullYear()+1}`:d
        const c = Date.parse(d)
        // console.log(today.getTime(), c, c<=today.getTime())
        if (c>=today.getTime()) {
            // console.log('approved')
            label = 'APPROVED'
            className = 'badge badge-success'
        } else {
            label = 'CLOSED'
            className = 'badge badge-secondary'
        }
        break;
      case 'rejected':
        label = 'REJECTED'
        className = 'badge badge-danger'
        break;
      default:
        label= 'STATUS not KNOW'
        className='badge badge-secondary'
    }
    return {
      label: label,
      className: className
    }
  }

  render() {

    return (
      <div>
        <span className={this.state.className}>
          {this.state.label}
        </span>
      </div>
    )
  }
}

export default BudgetStatus;
