import React from 'react'
import { PropagateLoader } from 'react-spinners'
import './FullSpinner.css'

class FullSpinner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: props.isLoading
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({
                isLoading: this.props.isLoading
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? (
                    <div className="full-spinner-container">
                        <div className="full-spinner-box">
                            <PropagateLoader
                                sizeUnit="px"
                                size={15}
                                color={'#fff'}
                                loading={true}
                            />
                        </div>
                    </div>
                ) : (
                    this.props.children
                )}
            </div>
        )
    }
}

export default FullSpinner
