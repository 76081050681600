const rp = require('request-promise')

const overallSchedule = (token, year) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/reports/schedules/' + year,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const overallActuals = (token, year) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/reports/actuals/' + year,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const dailySchedule = (token, year, month) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: `${url}/reports/dailyplans/${year}/${month}`,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const finance = (token, query) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/reports/finance',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const timesheet = (token, year, month, opts) => {
    let url = process.env.REACT_APP_SERVER
    let q = ''
    for (var variable in opts) {
        if (opts.hasOwnProperty(variable) && opts[variable] !== null) {
            q += `&${variable}=${opts[variable]}`
        }
    }
    return rp({
        url: `${url}/reports/timesheet/${year}/${month}?${q}`,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const budgets = (token, year, month, opts) => {
    let url = process.env.REACT_APP_SERVER
    let q = ''
    for (var variable in opts) {
        if (opts.hasOwnProperty(variable) && opts[variable] !== null) {
            q += `&${variable}=${opts[variable]}`
        }
    }
    return rp({
        url: `${url}/reports/budgets/${year}/${month}?${q}`,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getUserSkills = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/reports/skills',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const report = {
    overallSchedule: overallSchedule,
    overallActuals: overallActuals,
    dailySchedule: dailySchedule,
    finance: finance,
    timesheet: timesheet,
    budgets: budgets,
    getUserSkill: getUserSkills
}
export default report
