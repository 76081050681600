const rp = require('request-promise')

const getList = (token) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getFullList = (token, filters) => {
  let url = process.env.REACT_APP_SERVER;
  let p = ''
  if (filters){
    p = '?' + Object.entries(filters).map(kv => kv.map(encodeURIComponent).join("=")).join("&")
  }

  return rp({
    url: url+'/admin/budgets'+p,
    method: 'GET',
    json: true,
    body: filters,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getOne = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getAdminOne = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const create = (token, body) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets',
    method: 'POST',
    json: true,
    body: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateOne = (token, budgetId, body) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId,
    method: 'PUT',
    json: true,
    body: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateApprovedOne = (token, budgetId, body) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+ '/admin/budgets/'+ budgetId,
    method: 'PUT',
    json: true,
    body: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getUsers = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/users',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateUsers = (token, budgetId, users) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/users',
    method: 'PUT',
    json: true,
    body: {
      users: users
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getPlanning = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/planning',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getMonthPlanning = (token, budgetId, year, month) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/budgets/${budgetId}/plans/daily/${year}/${month}`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getActual = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/actual',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getExpenses = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/actual/expenses',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getActualByUserBudget = (token, budgetId, userId, year, month, timesheet, expenses) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/budgets/${budgetId}/actual/users/${userId}?year=${year}&month=${month}&timesheet=${timesheet}&expenses=${expenses}`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const moveActualByUserBudget = (token, budgetId, destBudget, userId, year, month, timesheet, expenses) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/budgets/${budgetId}/actual/users/${userId}`,
    method: 'PUT',
    body: {
      year: year,
      month: month,
      destinationBudget: destBudget,
      timesheet: timesheet,
      expenses: expenses,
    },
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const adminGetPlanning = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId+'/planning',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const adminGetActual = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId+'/actual',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updatePlanning = (token, budgetId, planning) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/planning',
    method: 'PUT',
    json: true,
    body: {
      planning: planning
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateDailyPlanning = (token, budgetId, year, month, planning) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/budgets/${budgetId}/plans/daily/${year}/${month}`,
    method: 'PUT',
    json: true,
    body: {
      planning: planning
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updatePlanningVisibility = (token, budgetId, status) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/planning/visibility',
    method: 'PUT',
    json: true,
    body: {
      visibleOnSchedule: status
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateUserDays = (token, budgetId, userId, rateId, value) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/users/'+userId+'/days',
    method: 'PUT',
    json: true,
    body: {
      rateId: rateId,
      days: value
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateSingleUserPlan = (token, budgetId, userId, rateId, year, month, value) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/budgets/${budgetId}/users/${userId}/hours/${year}/${month}`,
    method: 'PUT',
    json: true,
    body: {
      rateId: rateId,
      days: value
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const sendForApproval = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/tobeapproved',
    method: 'PUT',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const approve = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId+'/approve',
    method: 'PUT',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const reject = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId+'/reject',
    method: 'PUT',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const close = (token, budgetId, dateClose) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/admin/budgets/'+budgetId+'/close',
    method: 'PUT',
    json: true,
    body: {
        date: dateClose
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const deleteById = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/' + budgetId,
    method: 'DELETE',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}


const ListResources = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/' +  budgetId +'/resources',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const CreateResource = (token, budgetId, resource) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/' +  budgetId +'/resources',
    method: 'POST',
    json: true,
    body: resource,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}


const UpdateResource = (token, budgetId, resourceId, resource) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url + '/budgets/' +  budgetId +'/resources/' + resourceId,
    method: 'PUT',
    json: true,
    body: resource,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const DeleteResource = (token, budgetId, resourceId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/' +  budgetId +'/resources/' + resourceId,
    method: 'DELETE',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateApprovedForPM = (token, budgetId, body) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url + '/budgets/' +  budgetId +'/approved',
    method: 'PUT',
    json: true,
    body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateApprovedForAdmin = (token, budgetId, body) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url + '/admin/budgets/' +  budgetId +'/approved',
    method: 'PUT',
    json: true,
    body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getMilestones = (token, budgetId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/'+budgetId+'/milestones',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const createMilestone = (token, budgetId, milestone) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/budgets/' +  budgetId +'/milestones',
    method: 'POST',
    json: true,
    body: milestone,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateMilestone = (token, budgetId, milestoneId, milestone) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url + '/budgets/' +  budgetId +'/milestones/' + milestoneId,
    method: 'PUT',
    json: true,
    body: milestone,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const budget = {
  'getList': getList,
  'getOne': getOne,
  'getAdminOne': getAdminOne,
  'create': create,
  'updateOne': updateOne,
  'getUsers': getUsers,
  'updateUsers': updateUsers,
  'getActual': getActual,
  'adminGetActual': adminGetActual,
  'getPlanning': getPlanning,
  'updatePlanning': updatePlanning,
  'updatePlanningVisibility': updatePlanningVisibility,
  'getMonthPlanning': getMonthPlanning,
  'updateDailyPlanning': updateDailyPlanning,
  'getFullList': getFullList,
  'updateUserDays': updateUserDays,
  'updateSingleUserPlan': updateSingleUserPlan,
  'sendForApproval': sendForApproval,
  'adminGetPlanning': adminGetPlanning,
  'approve': approve,
  'reject': reject,
  'close': close,
  'deleteById': deleteById,
  'ListResources':ListResources,
  'CreateResource':CreateResource,
  'UpdateResource':UpdateResource,
  'DeleteResource':DeleteResource,
  'getActualByUserBudget': getActualByUserBudget,
  'moveActualByUserBudget': moveActualByUserBudget,
  'getExpenses': getExpenses,
  'updateApprovedOne': updateApprovedOne,
  'updateApprovedForPM': updateApprovedForPM,
  'updateApprovedForAdmin': updateApprovedForAdmin,
  'getMilestones': getMilestones,
  'createMilestone': createMilestone,
  'updateMilestone': updateMilestone,
}
export default budget;
