import info from './API/info'
import authenticate from './API/authenticate'
import user from './API/user'
import customers from './API/customers'
import organizations from './API/organizations'
import admin from './API/admin'
import budget from './API/budget'
import report from './API/report'
import employee from './API/employee'
import rates from './API/rates'
import resource from './API/resourcestype'
import skills from './API/skills'

var API = {
  'getStatus': info.getStatus,
  'login': authenticate.login,
  'userlogin' : authenticate.userlogin,
  'user': user,
  'customers': customers,
  'organizations': organizations,
  'admin': admin,
  'budget': budget,
  'report': report,
  'rates': rates,
  'employee': employee,
  'resourcetype': resource,
  'skills': skills
}

export default API;
