const rp = require('request-promise')

const getList = (token) => {
    let url = process.env.REACT_APP_SERVER;
  
    return rp({
      url: url + '/skills',
      method: 'GET',
      json: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
}

const addOne = (token, skill) => {
    let url = process.env.REACT_APP_SERVER;
  
    return rp({
      url: url + '/skills',
      method: 'POST',
      json: true,
      body: skill,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
}

const updateOne = (token, skillId, skill) => {
    let url = process.env.REACT_APP_SERVER;
  
    return rp({
      url: url + '/skills/' + skillId,
      method: 'PUT',
      json: true,
      body: skill,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
}



const skills = {
'getList': getList,
'addOne': addOne,
'updateOne': updateOne,
}
export default skills;