import React from 'react'
import { Divider, Dropdown, Avatar } from 'antd'
import _ from 'lodash'

import { LogoutOutlined, GoogleOutlined } from '@ant-design/icons'

import translate from '../../lib/i18n/i18n'
import packageJson from '../../../package.json'

import './userMenu.scss'

const UserMenu = (props) => {
    const { user, logout, children } = props

    let menu = null

    if (user) {
        menu = (
            <div className="user-menu-container">
                <div className="pt-3 px-3 d-flex flex-column justify-content-center align-items-center">
                    <Avatar src={user.picture} size="large" />
                    <p className="user-name no-select">{user.name}</p>
                    <p className="user-mail no-select">{user.mail}</p>
                </div>

                <a
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://myaccount.google.com/"
                >
                    <button
                        type="button"
                        title="Account Google"
                        className="btn btn-borderless btn-google-account d-flex justify-content-center align-items-center no-select px-3"
                    >
                        <GoogleOutlined />
                        <span>{translate.common.googleAccount}</span>
                    </button>
                </a>
                {user.roles && user.roles.indexOf('project-manager') > -1 ? (
                    <div>
                        <a href="#/projects/budgets">
                            <button
                                type="button"
                                title="Project Manager"
                                className="btn btn-borderless btn-google-account d-flex justify-content-center align-items-center no-select px-3"
                            >
                                Project Manager
                            </button>
                        </a>
                    </div>
                ) : null}
                {children}
                {process.env.REACT_APP_SHOWSKILLS === 'true' ? (
                    <div>
                        <a href="#/profile/skills">
                            <button
                                type="button"
                                title="Timesheet"
                                className="btn btn-borderless btn-google-account d-flex justify-content-center align-items-center no-select px-3"
                            >
                                Profile
                            </button>
                        </a>
                    </div>
                ) : null}
                <div className="weekie-version no-select">
                    {packageJson.name} {packageJson.version}
                </div>
                <Divider />
                <button
                    type="button"
                    title={translate.common.btn_logout}
                    className="btn btn-borderless btn-logout d-flex justify-content-center align-items-center no-select"
                    onClick={() => {
                        if (_.isFunction(logout)) {
                            logout()
                        }
                    }}
                >
                    <LogoutOutlined />
                    <span>{translate.common.logout}</span>
                </button>
            </div>
        )
    }

    return (
        <>
            {!user ? null : (
                <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    trigger={['click']}
                    className="dropdown-user-menu"
                >
                    <Avatar src={user.picture} className="no-select" />
                </Dropdown>
            )}
        </>
    )
}

export default UserMenu
