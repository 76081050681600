import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import './index.scss'
import 'antd/dist/antd.css'
import Routing from './Routing'
import it from 'antd/lib/locale/it_IT'
import en from 'antd/lib/locale/en_GB'

import * as serviceWorker from './serviceWorker'

require('dotenv').config()

const language =
    navigator.language === 'it' || navigator.userLanguage === 'it' ? it : en

ReactDOM.render(
    <ConfigProvider locale={language}>
        <Routing />
    </ConfigProvider>,
    document.getElementById('root')
)

serviceWorker.unregister()
