const rp = require('request-promise')

const getResourceType = (token) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/resources/`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getResource = (token, id) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/resources/${id}`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const UpdateResource = (token, id, resource) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/resources/${id}`,
    method: 'PUT',
    json: true,
    body: resource,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const SaveResource = (token, resource) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/resources/`,
    method: 'POST',
    json: true,
    body: resource,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const report = {
  'getResourceType': getResourceType,
  'getResource': getResource,
  'UpdateResource': UpdateResource,
  'SaveResource': SaveResource
}
export default report;
