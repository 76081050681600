const rp = require('request-promise')

const getRatesGroup = (token, year) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getRate = (token, id) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/${id}`,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const SaveRateDetail = (token, id, rate) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/${id}/rates`,
    method: 'POST',
    json: true,
    body: rate,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const cloneRate = (token, id) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/${id}/clone`,
    method: 'POST',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const UpdateRateDetail = (token, id, idrate, rate) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/${id}/rates/${idrate}`,
    method: 'PUT',
    json: true,
    body: rate,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const SaveRate = (token, rate) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/`,
    method: 'POST',
    json: true,
    body: rate,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const UpdateRate = (token, id, rate) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: `${url}/admin/rates/${id}`,
    method: 'PUT',
    json: true,
    body: rate,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const report = {
  'getRatesGroup': getRatesGroup,
  'getRate': getRate,
  'SaveRateDetail': SaveRateDetail,
  'UpdateRateDetail': UpdateRateDetail,
  'SaveRate': SaveRate,
  'UpdateRate': UpdateRate,
  'cloneRate': cloneRate
}
export default report;
