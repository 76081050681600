import LocalizedStrings from "react-localization";
import it from "./it.json";
import en from "./en.json";

let translate = new LocalizedStrings({
  en: en,
  it: it
});

export default translate;
