const rp = require('request-promise')

const login = async (code, cb)=>{
  let url = process.env.REACT_APP_SERVER;

  let payload = {
    code: code,
    redirect_url: window.location.origin
  };
  try {
    return await rp({
      method: 'POST',
      url: url+'/auth/login',
      body: payload,
      json: true
    })
  } catch (err) {
    return {
      error: err,

    }
  }
}

const userlogin = async (username, password, cb)=>{
  let url = process.env.REACT_APP_SERVER;

  let payload = {
    username: username,
    password: password
  };
  try {
    return await rp({
      method: 'POST',
      url: url+'/auth/user',
      body: payload,
      json: true
    })
  } catch (err) {
    return {
      error: err,
    }
  }
}
const authenticate = {
  'login': login,
  'userlogin': userlogin
}
export default authenticate;
