const rp = require('request-promise')

const getBudgets = (token, date) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/budgets',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token,
            datefrom: date
        }
    }).catch((e) => {
        throw e
    })
}

const getFulfillment = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/fulfillments',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).catch((e) => {
        throw e
    })
}

const closeMonth = (token, y, m) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/fulfillments/' + y + '/' + m,
        method: 'POST',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).catch((e) => {
        throw e
    })
}

const getTimesheets = (token, year, month) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/timesheet/' + year + '/' + month,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getTimekeeper = (token, year, month) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/timekeeper/' + year + '/' + month,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const updateTimesheet = (token, year, month, data) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/timesheet/' + year + '/' + month,
        method: 'PUT',
        json: true,
        body: data,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const updateTimekeeper = (token, year, month, data) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/timekeeper/' + year + '/' + month,
        method: 'PUT',
        json: true,
        body: data,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getExpenses = (token, year, month) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/expenses/' + year + '/' + month,
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const addExpense = (token, year, month, data) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/expenses/' + year + '/' + month,
        method: 'POST',
        body: data,
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const deleteExpense = (token, year, month, eId) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/expenses/' + year + '/' + month + '/' + eId,
        method: 'DELETE',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getExpenseTypes = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/expensetypes',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const getList = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/skillsall',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const userList = (token) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/skills',
        method: 'GET',
        json: true,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const userPush = (token, skillId, skill) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/skills/' + skillId,
        method: 'POST',
        json: true,
        body: skill,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const userPop = (token, skillId, level) => {
    let url = process.env.REACT_APP_SERVER

    return rp({
        url: url + '/employee/skills/' + skillId,
        method: 'PUT',
        json: true,
        body: level,
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

const employee = {
    getBudgets: getBudgets,
    getTimesheets: getTimesheets,
    getTimekeeper: getTimekeeper,
    updateTimesheet: updateTimesheet,
    updateTimekeeper: updateTimekeeper,
    getExpenseTypes: getExpenseTypes,
    getExpenses: getExpenses,
    getFulfillment: getFulfillment,
    closeMonth: closeMonth,
    addExpense: addExpense,
    deleteExpense: deleteExpense,
    userList: userList,
    userPush: userPush,
    userPop: userPop,
    getList: getList
}
export default employee
