const isJSON = require('is-valid-json');

var Storage = {
  'deleteAll': ()=>{
    return localStorage.clear();
  },
  'delete': (key)=>{
    return localStorage.removeItem(key);
  },
  'set': (key, value)=>{
    if( typeof value === 'object' ){
      value = JSON.stringify(value)
    }
    return localStorage.setItem(key, value);
  },
  'get': async (key)=>{
    let item =  await localStorage.getItem(key);
    if( isJSON(item) ){
      return JSON.parse(item);
    } else {
      return item;
    }
  }
}

export default Storage;
