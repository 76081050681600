const rp = require('request-promise')

const getList = (token) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/customers',
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const getOne = (token, cId) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/customers/'+cId,
    method: 'GET',
    json: true,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const updateOne = (token, cId, data) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/customers/'+cId,
    method: 'PUT',
    json: true,
    body: data,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const createOne = (token, data) => {
  let url = process.env.REACT_APP_SERVER;

  return rp({
    url: url+'/customers',
    method: 'POST',
    json: true,
    body: data,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

const customers = {
  'getList': getList,
  'getOne': getOne,
  'updateOne': updateOne,
  'createOne': createOne,

}
export default customers;
